

















import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutGuest: () => import("@/layouts/presets/guest.vue"),
  },
})
export default class PageWorkspaceSelect extends Vue {
  @Getter("auth/me") account!: Account;

  goToWorkspace(workspace: string) {
    const baseUrlParts = process.env.VUE_APP_WEB_URL.split("://");
    window.location.href = `${baseUrlParts[0]}://${workspace}.${baseUrlParts[1]}/overview`;
  }
}
